import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

if (window.SENTRY_DSN_JS) {
  Sentry.init({
    dsn: window.SENTRY_DSN_JS,
    environment: window.SENTRY_ENV,

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    release: window.SENTRY_VERSION,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
